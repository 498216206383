import React from 'react';
import {
     FormLabel, Box, Select
} from "@chakra-ui/react";

const CustomSelect = ({value, handleChange, id, data, label}) => {
    return (
        <Box>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <Select id={id} defaultValue={value} onChange={(e)=> handleChange(e)}>
                {data.map((dat, index)=> <option key={index} value={dat.value}>{dat.label}</option>)}
            </Select>
        </Box>
    )
}

export default CustomSelect;
