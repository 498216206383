import React  from "react";
import ReactPlayer from 'react-player'

const Video = ({video}) => {
    return (<>
            <ReactPlayer url={video} loop={true} controls={true} playing={true} width='100%' height='100%' />
        </>
    );
};

export default Video;