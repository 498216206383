import './App.css';
import React, {useContext, useEffect} from 'react';
import Header from './components/Header';
import {
    Container, SimpleGrid, Box, Spinner, Center
} from "@chakra-ui/react"
import MyCard from './components/Card';
import {DataContext} from "./providers/MainProvider";

function App() {
    const {fetchOnSales,items, loading} = useContext(DataContext);
    useEffect(() => {
        console.log("aqui")
        fetchOnSales("", "");
        // eslint-disable-next-line
    },[])

    console.log("items", items)
    return (
        <>
            <Header/>
            <Container marginTop={"20px"} maxW="container.xl">
                {items.length && !loading?   <SimpleGrid minChildWidth="200px"  spacing={6}>
                    {
                        items.map((property, index)=>
                            <MyCard key={index} property={property}/>)
                    }
                </SimpleGrid>:
                <Center><Box> {loading? <Spinner color='teal.500' />: "No Element found"}</Box></Center>}
            </Container>
        </>
    )
}

export default App;
