import React from 'react';
import {
 Button
} from "@chakra-ui/react"

const MyButton = ({title, ref = null, onClick, colorScheme = '', mr = 0, variant = ''}) => (
    <>
        <Button mr={mr} colorScheme={colorScheme} onClick={onClick} variant={variant}>
            {title}
        </Button>
    </>
);

export default MyButton;

