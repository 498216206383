import React, {useRef, useContext} from 'react';
import {
    Checkbox,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton, Input, Stack, Textarea, FormLabel, Box, InputGroup, InputRightAddon, CheckboxGroup
} from "@chakra-ui/react"
import MyButton from "../Button"
import CustomSelect from "../Select";
import {properties, bedrooms, investmentTiers, districts, houseTypes, bussinessTypes, ammenities} from "../../data"
import Video from "../Video";
import useForm from "../../hooks/useForm.js"
import {DataContext} from "../../providers/MainProvider";
import Spinner from "../Spinner"

function MyDrawer() {
    const {loading, isOpen, onOpen, onClose, isUploading} = useContext(DataContext)
    const firstField = useRef();

    const initialState = {
        property: "Commercial",
        bedroom: 'One',
        businessType: 'GUN STORE',
        'houseType': 'SMALL HOUSE',
        district: 'AIRPORT',
        investmentTier: 'Base'
    }
    const {data, handleChange, sendFile, onSubmit, setData} = useForm(initialState);

    async function onChange(e) {
        if (e.target.files.length && e.target.files[0] && e.target.files[0].name) {
            const file = e.target.files[0]
            sendFile(file)
        }

    }

    return (
        <>
            <MyButton variant={"solid"} colorScheme="blue" title="Create NFT" onClick={onOpen}/>
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                size={"md"}
            >
                <DrawerOverlay/>
                <DrawerContent>
                    <DrawerCloseButton/>
                    <DrawerHeader>{loading ? <Box color='red.500'>Creating NFT... <Spinner
                            color="red"
                            width={18}
                            height={18}
                            role="status"
                            aria-label="Loading"
                        /></Box> :
                        <Box color='teal.500'>Create your NFT
                        </Box>}</DrawerHeader>
                    <DrawerBody>
                        <Stack spacing="24px">
                            <Box>
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <Input
                                    ref={firstField}
                                    id="name"
                                    value={data["name"] || ""}
                                    placeholder="Enter the name of your NFT"
                                    onChange={(e) => handleChange(e)}
                                />
                            </Box>


                            <Box>
                                <FormLabel htmlFor="price">Price</FormLabel>
                                <InputGroup>
                                    <Input
                                        value={data["price"] || ""}
                                        type="text"
                                        id="price"
                                        placeholder="Price"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <InputRightAddon>Matic</InputRightAddon>
                                </InputGroup>
                            </Box>
                            <CustomSelect handleChange={handleChange} data={properties} label={"Select Property Type"}
                                          id={"property"} value={data["property"]}/>
                            <CustomSelect handleChange={handleChange} data={bedrooms} label={"Bedrooms"} id={"bedroom"}
                                          value={data["bedroom"]}/>
                            <CustomSelect handleChange={handleChange} data={bussinessTypes} label={"BUSSINESS TYPE"}
                                          id={"businessType"} value={data["businessType"]}/>
                            <CustomSelect handleChange={handleChange} data={houseTypes} label={"House Type"}
                                          id={"houseType"} value={data["houseType"]}/>
                            <CustomSelect handleChange={handleChange} data={districts} label={"District"}
                                          id={"district"} value={data["district"]}/>
                            <CustomSelect handleChange={handleChange} data={investmentTiers} label={"Investment Tier"}
                                          id={"investmentTier"} value={data["investmentTier"]}/>
                            <Box>
                                <FormLabel htmlFor="ammenities">Ammenities</FormLabel>
                                <CheckboxGroup
                                    id="ammenities"
                                    isInline
                                    spacing={8}
                                    variantColor="teal"
                                    onChange={(e) => handleChange(e)}
                                    defaultValue={data["ammenities"]}
                                >
                                    {ammenities.map((ammenity, index) => <Checkbox key={index}
                                                                                   value={ammenity.value}>{ammenity.label}</Checkbox>)}
                                </CheckboxGroup>
                            </Box>
                            <Box>
                                <FormLabel htmlFor="description">Description</FormLabel>
                                <Textarea id="description" value={data["description"] || ""}
                                          onChange={(e) => handleChange(e)}/>
                            </Box>

                            <Box>
                                <FormLabel htmlFor="desc">{isUploading ? <Box color='red.500'>Uploading your file...
                                    <Spinner
                                        color="red"
                                        width={18}
                                        height={18}
                                        role="status"
                                        aria-label="Loading"
                                    />
                                </Box> : "Upload your file"}
                                </FormLabel>
                                <input
                                    type="file"
                                    name="Asset"
                                    className="my-4"
                                    onChange={onChange}
                                    accept="video/mp4"

                                />
                                {/*<DropZone sendFile={sendFile}/>*/}
                            </Box>
                            {data["file"] ? <Box> <Video video={data["file"]}/> </Box> : null}
                        </Stack>
                    </DrawerBody>

                    <DrawerFooter>
                        <MyButton variant="outline" mr={3} onClick={() => {
                            onClose();
                            setData(initialState)
                        }} title="Cancel"/>
                        <MyButton colorScheme="blue" onClick={onSubmit} title={loading ? "Loading..." : "Mint"}/>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default MyDrawer;
