import { useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { nftMarket, nftAddress } from "../config";
import NFT from "../artifacts/contracts/ECityNFT.sol/ECityNFT.json";
import Market from "../artifacts/contracts/ECityMarketplace.sol/ECityMarketplace.json";
import axios from 'axios';
import { useDisclosure } from "@chakra-ui/react";

const opts = {
    network: "mumbai",
    cacheProvider: true,
}
const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0');
const rpcEndpoint = "https://polygon-mumbai.infura.io/v3/d5b54fa562cb45aba0a9fd370c3abe88"
const useMarket = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isBuying, setBuying] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();



    const mint = async ({ price, ...rest }) => {
        setLoading(true)
        const data = JSON.stringify({ ...rest });
        try {
            const added = await client.add(data);
            const url = `https://ipfs.infura.io/ipfs/${added.path}`;
            await createSale(url, price);

        } catch (error) {
            window.alert("Failed to create: " + error)
            console.log("error: ", error)
        }
    };

    const createSale = async (url, priceValue) => {
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()

            /* next, create the item */
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer)
            let transaction = await contract.createToken(url)
            let tx = await transaction.wait()
            let event = tx.events[0]
            let value = event.args[2]
            let tokenId = value.toNumber()
            const price = ethers.utils.parseUnits(priceValue, 'ether')
            /* then list the item for sale on the marketplace */
            contract = new ethers.Contract(nftMarket, Market.abi, signer)
            transaction = await contract.createMarketItem(nftAddress, tokenId, price)
            await transaction.wait()
            setLoading(false)
            onClose();
            await fetchOnSales()

        }
        catch (err) {
            setLoading(false)
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
        }
    }

    const uploadFileToIPFS = async (file) => {
        setUploading(true)
        try {
            const added = await client.add(
                file,
                {
                    progress: (prog) => console.log(`received: ${prog}`)
                }
            )
            const url = `https://ipfs.infura.io/ipfs/${added.path}`
            setUploading(false)
            return url
        } catch (error) {
            setUploading(false)
            console.log('Error uploading file: ', error)
            return null
        }
    }

    const loadData = async (state = "", category = "") => {
        setLoading(true)
        console.log("state", state)
        console.log("category", category)
        if (state === "my") {
            await fetchMyNFT()
        } else if (state === "sold") {
            await fetchSoldNFT()
        } else {
            await fetchOnSales(category);
        }
        setLoading(false)

    }

    const filterByCategory = async (category = '', state) => {
        await loadData(state, category)
    }

    const fetchOnSales = async (category = '') => {
        const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint)
        const tokenContract = new ethers.Contract(nftAddress, NFT.abi, provider)
        const marketContract = new ethers.Contract(nftMarket, Market.abi, provider)
        const data = await marketContract.fetchMarketItems()

        const items = await Promise.all(data.map(async i => {
            const tokenUri = await tokenContract.tokenURI(i.tokenId)
            const meta = await axios.get(tokenUri)
            let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
            let item = {
                price,
                itemId: i.itemId.toNumber(),
                seller: i.seller,
                owner: i.owner,
                description: meta.data.description,
                ammenities: meta.data.ammenities,
                property: meta.data.property,
                bedroom: meta.data.bedroom,
                businessType: meta.data.businessType,
                houseType: meta.data.houseType,
                district: meta.data.district,
                investmentTier: meta.data.investmentTier,
                file: meta.data.file,
                name: meta.data.name
            }
            return item
        }));
        const itemss = category.length ? items.filter(item => item.property === category) : items
        setData(itemss)
    }

    const fetchMyNFT = async () => {
        const web3Modal = new Web3Modal(opts)
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        const marketContract = new ethers.Contract(nftMarket, Market.abi, signer)
        const tokenContract = new ethers.Contract(nftAddress, NFT.abi, provider)
        const data = await marketContract.fetchMyNFTs()

        const items = await Promise.all(data.map(async i => {
            const tokenUri = await tokenContract.tokenURI(i.tokenId)
            const meta = await axios.get(tokenUri)
            let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
            let item = {
                price,
                tokenId: i.tokenId.toNumber(),
                seller: i.seller,
                owner: i.owner,
                image: meta.data.image,
                description: meta.data.description,
                ammenities: meta.data.ammenities,
                property: meta.data.property,
                bedroom: meta.data.bedroom,
                businessType: meta.data.businessType,
                houseType: meta.data.houseType,
                district: meta.data.district,
                investmentTier: meta.data.investmentTier,
                file: meta.data.file,
                name: meta.data.name
            }
            return item
        }))
        setData(items)
    }

    const fetchSoldNFT = async () => {
        const web3Modal = new Web3Modal(opts)
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        const marketContract = new ethers.Contract(nftMarket, Market.abi, signer)
        const tokenContract = new ethers.Contract(nftAddress, NFT.abi, provider)
        const data = await marketContract.fetchItemsCreated()

        const items = await Promise.all(data.map(async i => {
            const tokenUri = await tokenContract.tokenURI(i.tokenId)
            const meta = await axios.get(tokenUri)
            let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
            let item = {
                price,
                tokenId: i.tokenId.toNumber(),
                seller: i.seller,
                owner: i.owner,
                sold: i.sold,
                description: meta.data.description,
                ammenities: meta.data.ammenities,
                property: meta.data.property,
                bedroom: meta.data.bedroom,
                businessType: meta.data.businessType,
                houseType: meta.data.houseType,
                district: meta.data.district,
                investmentTier: meta.data.investmentTier,
                file: meta.data.file,
                name: meta.data.name
            }
            return item
        }));
        /* create a filtered array of items that have been sold */
        const soldItems = items.filter(i => i.sold)
        setData(soldItems);
    }

    const buyNFT = async (nft) => {
        setBuying(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftMarket, Market.abi, signer)

            const price = ethers.utils.parseUnits(nft.price.toString(), 'ether')
            const transaction = await contract.createMarketSale(nftAddress, nft.itemId, {
                value: price
            })
            await transaction.wait()
            await fetchOnSales();
            setBuying(false)
            window.alert("NFT purchase successful!")
        }
        catch (err) {
            setBuying(false)
            if (err.data && err.data.message) {
                window.alert("Failed to purchase: " + err.data.message)
            }
        }
    }


    return {
        data,
        mint,
        filterByCategory,
        buyNFT,
        fetchSoldNFT,
        fetchMyNFT,
        fetchOnSales,
        uploadFileToIPFS, loading, isOpen, onOpen, onClose, isUploading, isBuying
    };
};

export default useMarket;