
export const properties = [
    // {value: "", label: "Select your category"},
    { value: "Residential", label: "Residential" },
    { value: "Commercial", label: "Commercial" },
    { value: "AutoMobile", label: "AutoMobile" },
    { value: "Boat", label: "Boat" },
    { value: "Players Club Card", label: "Players Club Card" },
    { value: "Aircraft", label: "Aircraft" },
    { value: "Skin", label: "Skin" },
    { value: "Industrial", label: "Industrial" },
    { value: "Weapon", label: "Weapon" }
]

export const bedrooms = [
    { value: "One", label: "One" },
    { value: "Two", label: "Two" },
    { value: "Three", label: "Three" },
    { value: "Four", label: "Four" },
    { value: "Five", label: "Five" },
    { value: "Six", label: "Six" }
]

export const investmentTiers = [
    { value: "Base", label: "Base" },
    { value: "Level 1", label: "Level 1" },
    { value: "Level 2", label: "Level 2" },
    { value: "Level 3", label: "Level 3" },
    { value: "Level 4", label: "Level 4" },
    { value: "Level 5", label: "Level 5" },
    { value: "Level 6", label: "Level 6" },
    { value: "Level 7", label: "Level 7" }
]

export const districts = [
    { value: "AIRPORT", label: "AIRPORT" },
    { value: "BALLERS BOARDWALK", label: "BALLERS BOARDWALK" },
    { value: "BOATERS BLISS", label: "BOATERS BLISS" },
    { value: "CAMBRIDGE", label: "CAMBRIDGE" },
    { value: "CUSTOM COVE", label: "CUSTOM COVE" },
    { value: "EDEN", label: "EDEN" },
    { value: "ETHERIC CITY", label: "ETHERIC CITY" },
    { value: "LEGACY VILLAGE", label: "LEGACY VILLAGE" },
    { value: "PLEASURE ISLAND", label: "PLEASURE ISLAND" },
    { value: "PROMENADE", label: "PROMENADE" },
    { value: "ROYAL CIRCLES", label: "ROYAL CIRCLES" },
    { value: "UPTOWN", label: "UPTOWN" }
]

export const houseTypes = [
    { value: "SMALL HOUSE", label: "SMALL HOUSE" },
    { value: "LARGE HOME", label: "LARGE HOME" },
    { value: "CONDOMINIUM", label: "CONDOMINIUM" },
    { value: "MODERN HOME", label: "MODERN HOME" },
    { value: "MANSION", label: "MANSION" },
    { value: "CONDOMINUM BUILDING", label: "CONDOMINUM BUILDING" },
    { value: "APARTMENT BUILDING", label: "APARTMENT BUILDING" },
    { value: "DUPLEX", label: "DUPLEX" },
    { value: "TRIPLEX", label: "TRIPLEX" },
    { value: "QUADPLEX", label: "QUADPLEX" },
    { value: "VACANT LAND", label: "VACANT LAND" },
    { value: "HIGH RISE APARTMENTS", label: "HIGH RISE APARTMENTS" },
    { value: "HIGH RISE CONDOMINIUMS", label: "HIGH RISE CONDOMINIUMS" }
]

export const bussinessTypes = [
    { value: "GUN STORE", label: "GUN STORE" },
    { value: "CLOTHING STORE", label: "CLOTHING STORE" },
    { value: "SMOKE SHOP", label: "SMOKE SHOP" },
    { value: "BAR / RESTAURANT", label: "BAR / RESTAURANT" },
    { value: "CAR DEALER", label: "CAR DEALER" },
    { value: "BOAT DEALER", label: "BOAT DEALER" },
    { value: "HIGH END CAR DEALER", label: "HIGH END CAR DEALER" },
    { value: "HIGH END CLOTHING STORE", label: "HIGH END CLOTHING STORE" },
    { value: "HIGH END BOAT DEALER", label: "HIGH END BOAT DEALER" },
    { value: "CONVENIENCE STORE", label: "CONVENIENCE STORE" },
    { value: "GAME LEVEL", label: "GAME LEVEL" },
    { value: "CUSTOM BUSSINESS", label: "CUSTOM BUSSINESS" },
    { value: "AIRCRAFT DEALER", label: "AIRCRAFT DEALER" },
    { value: "MOD SHOP", label: "MOD SHOP" },
    { value: "HANGER", label: "HANGER" },
    { value: "VACANT LAND", label: "VACANT LAND" },
    { value: "PRIVATE AIRPORT", label: "PRIVATE AIRPORT" },
    { value: "POWER PLANT", label: "POWER PLANT" },
    { value: "SHIPPING INDUSTRY", label: "SHIPPING INDUSTRY" },
    { value: "MINING OPERATION", label: "MINING OPERATION" },
    { value: "MANUFACTURER", label: "MANUFACTURER" },
    { value: "ART GALLERY", label: "ART GALLERY" },
    { value: "RADIO STATION", label: "RADIO STATION" }
]

export const ammenities = [
    { value: "DIVIDABLE", label: "DIVIDABLE" },
    { value: "POOL", label: "POOL" },
    { value: "REFLECTION POND", label: "REFLECTION POND" },
    { value: "WATERFRONT", label: "WATERFRONT" },
    { value: "LIMITED EDITION", label: "LIMITED EDITION" },
    { value: "BETA ACCESS", label: "BETA ACCESS" },
    { value: "PENTHOUSE SUITE", label: "PENTHOUSE SUITE" },
    { value: "COVERED PARKING", label: "COVERED PARKING" },
    { value: "GATED COMMUNITY", label: "GATED COMMUNITY" },
    { value: "PRIVATE PARK", label: "PRIVATE PARK" },
    { value: "SKYSCRAPER", label: "SKYSCRAPER" },
    { value: "BALCONY", label: "BALCONY" },
]


