import { useState, useContext } from "react";
import { hasRequiredKeys } from "../helpers";
import { DataContext } from "../providers/MainProvider";

const useForm = (defaultValue = {}) => {
    const [data, setData] = useState(defaultValue);
    const { mint, uploadFileToIPFS, isUploading } = useContext(DataContext)

    const handleChange = (e) => {
        let payload = {}
        if (!e.target) {
            // eslint-disable-next-line
            payload = { ["ammenities"]: e, ...data }
        } else {
            payload = {
                ...data, [e.target.id]: e.target.value
            }
        }
        setData(payload);
    }


    const sendFile = async (file) => {
        console.log("file: " + JSON.stringify(file))
        const url = await uploadFileToIPFS(file);
        console.log("url", url);
        setData({ ...data, file: url });
    }

    const onSubmit = async () => {
        if (isUploading) {
            window.alert("File is uploading, please wait.");
            return;
        }
        if (hasRequiredKeys(data)) {
            if (isNaN(data.price)) {
                window.alert("Invalid price!");
                return;
            }
            await mint(data);
            setData(defaultValue);

        } else {
            window.alert("Please complete all fields");
            return;
        }
    }


    return {
        data,
        handleChange,
        sendFile,
        onSubmit,
        setData
    };
};

export default useForm;