import React, {useContext, useState, useEffect} from "react";
import {
    Box,
    Stack,
    Heading,
    Flex,
     Select,
    useDisclosure
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import MyDrawer from '../Drawer';
import {properties} from "../../data"
import {DataContext} from "../../providers/MainProvider";


const Header = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cat, setCat] = useState('Skin');
    const [status, setStatus] = useState('Skin');
    const handleToggle = () => (isOpen ? onClose() : onOpen());
    const {filterByCategory,
        fetchSoldNFT,
        fetchMyNFT,
        fetchOnSales} = useContext(DataContext)

    const handleChange = (e) => {
        console.log(e.target.value)
        if(e.target.value ==="my"){
            fetchMyNFT()
            setStatus(e.target.value)
        }else if(e.target.value === "sale"){
            setStatus(e.target.value)
            fetchOnSales()
        }else if(e.target.value ==="sold"){
            setStatus(e.target.value)
            fetchSoldNFT()
        }else {
            setCat(e.target.value)
            filterByCategory(e.target.value, cat)
        }
    }

    // useEffect(()=>{
    //     fetchOnSales(cat)
    //     // eslint-disable-next-line
    // },[cat])





    useEffect(()=>{

    },[status])



    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding={6}
            bg="teal.500"
            color="white"
            {...props}
        >
            <Flex align="center" mr={5}>
                <Heading as="h1" size="lg" letterSpacing={"tighter"}>
                    Etheric City
                </Heading>
            </Flex>

            <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
                <HamburgerIcon />
            </Box>

            <Stack
                direction={{ base: "column", md: "row" }}
                display={{ base: isOpen ? "block" : "none", md: "flex" }}
                width={{ base: "full", md: "auto" }}
                alignItems="center"
                mt={{ base: 4, md: 0 }}
            >
                <Select placeholder="Categories" id="cat" defaultValue="" onChange={(e)=> handleChange(e)}>
                    {properties.map((item, index) => <option key={index} value={item.value}>{item.label}</option> )}
                </Select>

                <Select placeholder="Sort By" onChange={(e)=> handleChange(e)}>
                    <option value="my">My Items</option>
                    <option value="sold">Sold Items</option>
                    <option value="sale">On Sale</option>
                </Select>
            </Stack>

            <Box
                display={{ base: isOpen ? "block" : "none", md: "block" }}
                mt={{ base: 4, md: 0 }}
            >
                <MyDrawer/>
            </Box>
        </Flex>
    );
};

export default Header;
