
import {
    Box, Badge
} from "@chakra-ui/react"
import MyButton from "../Button";
import {useContext, useState} from "react";
import {DataContext} from "../../providers/MainProvider";
import Video from "../Video";

const MyCard = ({property}) =>{
    const {buyNFT, isBuying} = useContext(DataContext)
    const [item, setItem] = useState(null)

    const handleData = (property) => {
        setItem(property.itemId || null)
        buyNFT(property)
    }
    return (
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Box> <Video video={property.file}/> </Box>
            {/*<Image src={property.imageUrl} alt={property.title} />*/}
            <Box p="6">
                <Box display="flex" alignItems="baseline">
                    <Badge borderRadius="full" px="2" colorScheme="teal">
                        {property.property}
                    </Badge>
                    <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                        ml="2"
                    >
                        {property.bedroom} beds &bull; {property.ammenities.map((ammenity)=> ammenity)} baths
                    </Box>
                </Box>

                <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                >
                    {property.name}
                </Box>

                <Box>
                    {property.price} Matic
                    <Box ml={"12px"} as="span" color="gray.600" fontSize="sm">
                        <MyButton variant="solid" colorScheme={"pink"} title={isBuying && item === property.itemId? "Buying...": "Buy"} onClick={()=> handleData(property)}/>
                    </Box>
                </Box>

                <Box as="span"  color="gray.600" fontSize="sm">
                    Bussines Type: {property.businessType}
                </Box>
                <Box as="span" ml="2" color="gray.600" fontSize="sm">
                    district: {property.district}
                </Box>
                <Box as="span" ml="2" color="gray.600" fontSize="sm">
                    Seller: {property.seller}
                </Box>
            </Box>
        </Box>
    )
}

export default MyCard