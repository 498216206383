import React,{ createContext } from 'react';
import { ChakraProvider } from "@chakra-ui/react"
import useMarket from "../hooks/useMarket.js"

export const DataContext = createContext(null);

const MainProvider = ({children}) =>{
    const {
        data,
        mint,
        filterByCategory,
        buyNFT,
        fetchSoldNFT,
        fetchMyNFT,
        fetchOnSales, uploadFileToIPFS, loading, isUploading, isOpen, onOpen, onClose, isBuying} = useMarket();
    return (
        <ChakraProvider>
        <DataContext.Provider value={{items: data,loading, isBuying, mint, filterByCategory, buyNFT, fetchSoldNFT, fetchMyNFT, fetchOnSales, uploadFileToIPFS, isUploading, isOpen, onOpen, onClose}}>
            {children}
        </DataContext.Provider>
        </ChakraProvider>
    );
}

export default MainProvider;